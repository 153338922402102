body {
  background: url("https://soundingclimate-media.s3.us-east-2.amazonaws.com/images/interface/tAnom.0181.jpg") repeat top left;
  background-size: cover;
}
.hp-container {
  background: url("https://soundingclimate-media.s3.us-east-2.amazonaws.com/images/interface/tAnom.0181.jpg") repeat top left;
background-size: cover;
  display: flex;
  flex-direction: column;
}
.hpBtn {
  border:none;
  background-color: transparent;
}
.hp-title-container,
.hp-desc-container,
.hp-btn-container,
.hp-qr-container,
.hp-link-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  border: 1px solid transparent;
}
.hp-link-container a {
  font-weight: bold;
  color: white;
  background-color: black;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 1.1rem;
  border-radius: 20px;
}
.hp-link-container a:hover {
  text-decoration: underline;
}
.hp-btn {
  width: 100%;
  max-width: 540px;
  margin: 50px;
}
.hp-title-container {
  font-family: Verdana, sans-serif;
  font-size:5rem;
  font-weight: bold;
  color: white;
  -webkit-text-stroke: 1px black;
  text-align: center;
}
.hp-desc-container {
  font-family: Verdana, sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  -webkit-text-stroke: 1px rgb(51, 51, 51);
  text-align: center;
  margin-left:20px;
  margin-right: 20px;
}
.hp-qr-container {
  margin-top: 20px;
  margin-bottom:20px;
}
.hp-qr {
  max-width:200px;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .hp-btn {
    max-width: 90%;
    margin: 20px;
  }
}
